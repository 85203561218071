#team{
    #team-tile{
        padding-bottom: 4rem;
    }
    #guests-tile{
        padding: 2rem 0;
    }
    .hosts-wrapper{
        display: flex;
        width: 100%;
    }
    .hosts{
        display: inline-flex;
        width: 65%;
        @include mq('tablet'){
            flex-direction: column;
        }
        .host{
            width: 50%;
            padding-left: .75rem;
            padding-right: .75rem;
            @include mq('tablet'){
                width: 100%;
                display: flex;
                padding: 0;
                margin-bottom: 3rem;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
            .block-content{
                background-color: darken(white, 10%);
                background-size: cover;
                width: 100%;
                // background-position: center;
                min-height: 20rem;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;
                h2{
                    position: absolute;
                    bottom: -1rem;
                    left: 1rem;
                    background: $secondary;
                    color: white;
                    padding: .5rem;
                    padding-right: 1.5rem;
                    padding-top: .75rem;
                    padding-left: .75rem;
                    @extend .card-shadow;
                }
            }
        }
    }
    #jh-tile {
        border-top:1px solid rgba(255,255,255,0.15);
        box-shadow:0 -1em 2em rgba(0,0,0,0.5);
        background:#000 url(/img/detroit-city.jpg) center center no-repeat;
        text-shadow:0 3px 1px rgba(0,0,0,0.7);
        background-size:cover;
        padding:4rem 0;
        ul {
            margin-top:2em;
        }
    }
    .jh-logo {
        float:right;
        margin-left:2em;
        margin-bottom:1em;
        @media screen and (max-width:40em){
            float:none;
            margin:0 0 2em;
            text-align:center;
        }
        img { max-width:50vw; }
    }
}
