@import url('https://fonts.googleapis.com/css?family=Oswald');

// FONTS
$headline: 'Oswald', serif;
$body: Helvetica, Arial, sans-serif;

$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "container":    1100px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

$z-indexes: (
    "site-header",
    "mobile-nav",
    "raised",
    "site-wrapper"
);

// BRAND COLORS
$primary: #002E6D;
$secondary: #FF7F30;

$light-1: darken(white, 10%);
$light-2: darken(white, 20%);
$light-3: darken(white, 30%);
$light-4: darken(white, 40%);
$grey: lighten(black, 50%);
$dark-4: lighten(black, 40%);
$dark-3: lighten(black, 30%);
$dark-2: lighten(black, 20%);
$dark-1: lighten(black, 10%);

$footer-background: #00225a;

// UTILITY COLORS
$error-color: #D8000C;
$error-background: #FFBABA;
$success-color: #4F8A10;
$success-background: #DFF2BF;

// SOCIAL MEDIA COLORS
$facebook: #3b5998;
$twitter: #00aced;
$googleplus: #df4a32;
$pinterest: #cb2027;
$instagram: #bc2a8d;

