#home{
  article{
    h3{
      font-family: $headline;
      text-transform:uppercase;
      font-size: 1.75rem;
    }
    p{
      line-height: 1.75rem;
      font-size: 1.05rem;
    }
  }
  #home-blocks-wrapper{
    // padding: 10rem 0 2rem;
    padding: 2rem 0;
    .row{
      margin-bottom: 1.5rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .box-wrapper{
      padding-left: .75rem;
      padding-right: .75rem;
      display: inline-block;
      float: left;
      min-height: 16rem;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
      &.episode-preview{
        width: 100%;
        .box{
          justify-content: flex-start;
          align-items: flex-end;
          .box-content{
            position: relative;
            @extend .text-shadow;
            .btn{
              margin-top: 1rem;
            }
          }
          .episode-number{
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            align-self: flex-start;
            margin-bottom: .25rem;
          }
          .episode-date{
            display: block;
            margin-bottom: .5rem;
            color: white;
            font-size: .8rem;
            font-style: italic;
          }
          .episode-title{
            font-family: $headline;
            text-transform:uppercase;
            color: white;
            margin-top: .75rem;
            font-size: 1.9rem;
            font-weight: bold;
            margin-bottom: .25rem;
            line-height: 2rem;
          }
          .episode-guest{
            color: white;
            text-transform: uppercase;
            font-style: italic;
            @include mq('phablet'){
              position: static;
              top: auto;
              right: auto;
              margin-bottom: 1rem;
            }
          }
          .episode-intro{
            margin-bottom: 1rem;
            flex-direction: row;
            p{
              color: white;
              font-size: 1.05rem;
              .read-more{
                display: inline-flex;
              }
            }
          }
        }
        @include mq('tablet'){
          width: 100%;
          padding: 0;
          margin-bottom: 0;
        }
      }
      &.quote-box{
        width: 33.333333%;
        text-align: center;
        p{
          font-size: 1.5rem;
          color: white;
        }
        .box{
          background-image: url('/img/quote-box.jpg');
        }
        @include mq('tablet'){
            width: 100%;
            padding: 0;
        }
      }
      &.about, &.episodes, &.team{
        width: 50%;
      }
      &.team{
        .box{
          background-position:left center;
          background-image: url('/img/microphone.jpg');
        }
        @include mq('tablet'){
          width: 50%;
        }
        @include mq('phablet'){
          width: 100%;
          padding: 0;
        }
      }
      &.about{
        .box{
          background-image: url('/img/about-box.jpg');
        }
        @include mq('tablet'){
          width: 100%;
          padding: 0;
          margin-bottom: .75rem;
        }
      }
      &.episodes{
        .box{
          background-position:right center;
          background-image: url('/img/times-square.jpg');
        }
        @include mq('tablet'){
          width: 50%;
          padding-left: 0;
        }
        @include mq('phablet'){
          width: 100%;
          padding: 0;
          margin-bottom: .75rem;
        }
      }
    }
    .box{
      background-color: darken(white, 30%);
      min-height: 22rem;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 1rem;
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      @extend .text-shadow;
      @include mq('tablet'){
        min-height: 16rem;
      }
      .cover{
        background: $primary;
        opacity: .5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: z('site-wrapper');
        @include ease();
      }
      .box-content{
        z-index: z('raised');
      }
      h2{
        color: white;
        font-family: $primary;
        text-transform: uppercase;
        font-size: 1.15rem;
        text-align: center;
        line-height: 1.5rem;
      }
      @include mq('tablet', 'min'){
        &:hover{
          .cover{
            opacity: .6;
          }
        }
      }
    }
    @include mq('container'){
      padding: .75rem 0;
      .row{
        margin-bottom: .75rem;
      }
      .box-wrapper{
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        &:first-of-type{
          padding-left: 0;
        }
        &:last-of-type{
          padding-right: 0;
        }
      }
    }
  }
}
