#episodes{
    .episode-box-wrapper{
        padding: .75rem;
        width:50%;
        float:left;
        @include mq('tablet'){
            float:none;
            width:auto;
        }
    }
    .episode-box{
        background-color: darken(white, 50%);
        background-size: cover;
        background-repeat: no-repeat;
        // background-position: center;
        // padding: 1.25rem;
        position: relative;
        display: flex;
        max-width:100%;
        align-items: stretch;
        min-height: 20rem;
        .cover{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $primary;
            z-index: z('site-wrapper');
            opacity: .35;
            // display: none;
        }
        .box-content{
            z-index: z('raised');
            padding: 1.5rem;
            display: flex;
            // align-self: flex-start;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-grow: 1;
            overflow:hidden;
        }
        .btn{
            margin-top: .5rem;
        }
        .episode-title{
            font-family: $headline;
            text-transform:uppercase;
            color: white;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: .25rem;
            line-height: 2.25rem;
            width:100%;
            @extend .text-shadow;
        }
        .episode-intro{
            color: white;
            margin-bottom: 1rem;
            p{
                color: white;
            }
        }
        .episode-guest{
            text-transform: uppercase;
            color: white;
            font-style: italic;
            margin-bottom: .75rem;
            font-size: 1.15rem;
        }
        .episode-number{
            text-transform: uppercase;
            color: white;
            font-weight: bold;
            margin-bottom: auto;
            @extend .text-shadow;
        }
    }
    .episode-boxes{
        margin: -.75rem;
        // grid-column-gap: .75rem;
        // grid-row-gap: .75rem;
        .episode-box{
            // padding-right: .75rem;
        }
        @include mq('tablet'){
            grid-template-columns: 100%;
            margin: 0;
            .episode-box-wrapper{
                padding: 0;
                padding-bottom: .75rem;
                &:last-of-type{
                    padding-bottom: 0;
                }
            }
        }
    }
}
